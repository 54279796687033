<template>
  <UiPopup
    :model-value="modelValue"
    title="Turn off 2FA"
    :description="`Are you sure you want to turn off 2FA verification for user ${user?.name}?`"
    primary-button-text="Turn off"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="turnOffTwoFA"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import type { User } from '@/types'
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  user: User
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const turnOffTwoFA = async () => {
  loading.value = true
  try {
    await useDisableTwoFA(Number(props.user.id))
    uiStore.showSnackBanner()
    emits('input')
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
